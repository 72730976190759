<template>
  <div class="page">
    <div class="page-header">
      <el-button
        v-if="activeName == 'officeFirmware'"
        type="primary"
        @click="newVersionNotificationSettings"
        v-has="'newVersion'"
      >
        {{ $t('firmware.newVersionNotificationSettings') }}
      </el-button>
      <el-button v-else type="primary" @click="addCustomerFirmware" v-has="'upload'">
        {{ $t('firmware.uploadFirmware') }}
      </el-button>
    </div>
    <div class="page-main bg-transparent">
      <el-tabs v-model="activeName">
        <el-tab-pane
          v-if="!isLAN"
          :label="$t('firmware.officeFirmware')"
          name="officeFirmware"
          lazy
        >
          <tab-office-firmware @pushItem="pushItem"></tab-office-firmware>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('firmware.customerFirmware')"
          name="customerFirmware"
          lazy
        >
          <tab-customer-firmware @pushItem="pushItem"></tab-customer-firmware>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 邮件提醒设置 -->
    <dailog-new-version-notification-settings
      v-if="settingsVisible"
      :visible.sync="settingsVisible"
    ></dailog-new-version-notification-settings>
    <!-- 推送任务 -->
    <dialog-configuration-push
      ref="configurationPush"
      v-if="pushVisible"
      :visible.sync="pushVisible"
      :title="pushTitle"
      :columnData="columnData"
      :modelOptions="modelOptions"
      :req="pushReq"
      :toolsDisplay="{
        modelShow: true,
        siteShow: true,
        keywordsShow: true,
      }"
      @scheduled="scheduledChange"
    ></dialog-configuration-push>
    <!-- 推送定时任务 -->
    <dialog-scheduled-update
      v-if="scheduledUpdateVisible"
      :visible.sync="scheduledUpdateVisible"
      :req="scheduledUpdateReq"
    ></dialog-scheduled-update>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TabOfficeFirmware from './tab-office-firmware'
import TabCustomerFirmware from './tab-customer-firmware'
import DailogNewVersionNotificationSettings from './dailog-new-version-notification-settings'
import DialogConfigurationPush from '../../Configuration/dailog-push/dialog-configuration-push'
import DialogScheduledUpdate from '../../Configuration/dailog-push/dialog-scheduled-update'

export default {
  name: 'Firmware',
  components: {
    TabOfficeFirmware,
    TabCustomerFirmware,
    DailogNewVersionNotificationSettings,
    DialogConfigurationPush,
    DialogScheduledUpdate,
  },
  data() {
    return {
      activeName: 'officeFirmware',
      settingsVisible: false,
      settingsReq: {},
      // 实时推送
      pushVisible: false,
      pushTitle: this.$t('pushFirmwareUpdate'),
      columnData: [
        {
          label: this.$t('device.macAddress'),
          prop: 'macAddress',
          minWidth: 150,
          filter: 'toUpperCase',
        },
        {
          label: this.$t('device.deviceName'),
          prop: 'deviceName',
          minWidth: 150,
        },
        {
          label: this.$t('device.deviceModel'),
          prop: 'deviceModel',
          minWidth: 120,
        },
      ],
      pushReq: {},
      modelOptions: [],
      // 定时推送
      scheduledUpdateVisible: false,
      scheduledUpdateReq: {},
    }
  },
  computed: {
    ...mapState('session', ['isLAN'])
  },
  created() {
    this.activeName = this.$route.params.activeName
      ? this.$route.params.activeName
      : this.isLAN
        ? 'customerFirmware'
        : 'officeFirmware'
  },
  methods: {
    // 邮件提醒设置
    newVersionNotificationSettings() {
      this.settingsVisible = true
    },
    // 新增
    addCustomerFirmware() {
      this.$router.push('/firmware/uploadFirmware')
    },
    // 推送
    pushItem(row) {
      this.modelOptions = row.supportModel.split(',').map(item => ({ label: item, value: item }))
      this.pushVisible = true
      this.pushReq.row = {
        id: row.id,
        relationName: 'firmware',
        // model: row.supportModel,
      }
    },
    // 推送定时任务
    scheduledChange(val) {
      this.scheduledUpdateVisible = true
      this.scheduledUpdateReq.data = val
    },
  },
}
</script>
