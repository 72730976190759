<template>
  <div class="office">
    <div class="page-tools">
      <el-form :inline="true" :model="tabelSearch">
        <!-- <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-delete iconfont icon-delete"
            @click="deleteItem()"
            :disabled="!tableSelection.length"
            v-has="'delete'"
            >{{ $t('delete') }}
          </el-button>
        </el-form-item> -->
        <el-form-item class="page-tools-right keywords" v-has="'list'">
          <el-input
            v-model="tabelSearch.keywords"
            :placeholder="$t('firmware.keywordsPla')"
            prefix-icon="el-icon-search"
            clearable
            @change="getTabelData"
          ></el-input>
        </el-form-item>
        <el-form-item class="page-tools-right" v-has="'list'">
          <select-model
            v-model="tabelSearch.supportModel"
            selectAll
            @change="getTabelData"
          ></select-model>
        </el-form-item>
      </el-form>
    </div>
    <table-pagination
      memory="tab-office-firmware"
      ref="refTable"
      :height="pageTabelHeight - 64"
      :tableData="tableData"
      :columnData="columnData"
      @selection-change="selectionChange"
      optionShow
      columnOptionShow
      :total="tabelTotal"
      :currentPage.sync="tabelSearch.currentPage"
      :pageSize.sync="tabelSearch.pageSize"
      @changeCurrentPage="getTabelData"
      @sort-change="tabelSortChange"
    >
      <!-- option -->
      <template slot="option" slot-scope="scope">
        <el-tooltip
          effect="dark"
          :content="$t('push')"
          placement="top"
          :open-delay="1000"
          v-has="'push'"
        >
          <el-button type="text" class="btn-p0" @click="pushItem(scope.row)">
            <i class="el-icon-s-promotion iconfont icon-push"></i>
          </el-button>
        </el-tooltip>
        <el-tooltip
          v-if="scope.row.uploadRealFileUrl"
          effect="dark"
          :content="$t('download')"
          placement="top"
          :open-delay="1000"
          v-has="'download'"
        >
          <el-button type="text" class="btn-p0" @click="downloadItem(scope.row)">
            <i class="el-icon-download"></i>
          </el-button>
        </el-tooltip>
      </template>
    </table-pagination>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { TablePagination, SelectModel } from '@/components'
import {
  frimwareSearch,
  firmwareRemoveBatch,
  // firmwareDownLoad
} from '@/api/firmware'
import { downloadFileUrl } from '@/plugins/methods'

export default {
  name: 'tab-office-firmware',
  components: {
    TablePagination,
    SelectModel,
  },
  data() {
    return {
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'updateTime',
        sortOrder: 'desc',
        firmwareType: 0,
        keywords: null,
        supportModel: null,
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('firmware.firmwareName'),
          prop: 'firmwareName',
          minWidth: '160',
          sortable: 'custom',
        },
        {
          label: this.$t('firmware.versionNumber'),
          prop: 'firmwareVersion',
          minWidth: '170',
        },
        {
          label: this.$t('firmware.supportModel'),
          prop: 'supportModel',
          minWidth: '160',
        },
        {
          label: this.$t('lastUpdated'),
          prop: 'updateTime',
          minWidth: '160',
          sortable: 'custom',
          filter: 'dateFormat',
        },
        {
          label: this.$t('model.description'),
          prop: 'firmwareDesc',
          minWidth: '160',
        },
        {
          label: this.$t('firmware.fileSize'),
          prop: 'fileSize',
          minWidth: '120',
          sortable: 'custom',
        },
      ],
      tableData: [],
      tableSelection: [],
    }
  },
  computed: {
    ...mapState('memory', ['sidebarSite']),
    ...mapGetters('session', ['pageTabelHeight']),
    tabelParams() {
      let data = { ...this.tabelSearch }
      data.site = this.sidebarSite
      return data
    },
  },
  watch: {
    'tabelParams.siteId'() {
      this.getTabelData()
    },
  },
  created() {
    this.getTabelData()
  },
  methods: {
    // 获取数据
    getTabelData() {
      frimwareSearch(this.tabelParams)
        .then(({ data }) => {
          this.tableData = data.result.rows // 表格数据
          this.tabelTotal = data.result.totalRows // 表格数据条目
          // this.$refs.refTable.clearFilter() // 表格过滤清空
        })
        .catch(() => {})
    },
    // 排序
    tabelSortChange({ order, prop }) {
      this.tabelSearch.sortOrder = order ? order : 'desc'
      this.tabelSearch.sortField = order ? prop : 'updateTime'
      this.getTabelData()
    },
    // 多选
    selectionChange(val) {
      this.tableSelection = val
    },
    // 删
    deleteItem() {
      this.$confirm(this.$t('firmware.deleteTip'), this.$t('tip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
        .then(() => {
          const ids = this.tableSelection.map((e) => e.id),
            args = this.tableSelection.map((e) => e.firmwareName)
          firmwareRemoveBatch({ ids, args })
            .then(({ data }) => {
              this.$message.success(data.message)
              this.getTabelData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 下载
    downloadItem({ uploadRealFileUrl }) {
      downloadFileUrl(uploadRealFileUrl)
    },
    // 推送
    pushItem(row) {
      this.$emit('pushItem', row)
    },
  },
}
</script>

<style lang="scss" scoped>
.office {
  height: 100%;
  background-color: #FFF;
}
.page-tools {
  padding: 16px 24px 2px 30px !important;
}
</style>