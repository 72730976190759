<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <p style="margin-bottom: 20px;">{{ $t('firmware.newVersionNotificationSettingsTip') }}</p>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center"> </el-table-column>
      <el-table-column prop="model" :label="$t('device.model')" align="left"></el-table-column>
    </el-table>
    <span class="footer-btns" slot="footer">
      <el-button class="footer-btn" type="primary" plain @click="handleClose">{{ $t('cancel') }}</el-button>
      <el-button class="footer-btn" type="primary" @click="submit">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { commonModel } from '@/api/app'
import { getNewVersionNotify, setNewVersionNotify } from '@/api/firmware'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default() {
        return this.$t('firmware.newVersionNotificationSettings')
      },
    },
  },
  data() {
    return {
      tableData: [],
      multipleSelection: [],
    }
  },
  created() {
    this.getModel()
  },
  methods: {
    // 获取值
    getData() {
      getNewVersionNotify()
        .then(({ data }) => {
          const rows = data.result.rows
          this.toggleSelection(this.tableData.filter((e) => rows.includes(e.model)))
        })
        .catch(() => {})
    },
    // 获取model列表
    getModel() {
      commonModel()
        .then(({ data }) => {
          this.tableData = data.result.rows.map((e) => {
            return { model: e }
          })
          this.getData()
        })
        .catch(() => {})
    },
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 确定
    submit() {
      setNewVersionNotify({ models: this.multipleSelection.map((e) => e.model) })
        .then(({ data }) => {
          this.$message.success(data.message)
          this.handleClose()
        })
        .catch(() => {})
    },
    toggleSelection(rows) {
      rows.forEach((row) => {
        this.$refs.multipleTable.toggleRowSelection(row)
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__header-wrapper {
  font-size: medium;
}
::v-deep .el-table {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  .is-left  {
    text-align: left;
    color: #333;
    .cell {
      text-align: inherit;
    }
  }
  th.is-leaf, td {
    border-bottom: transparent;
  }
}
</style>
